@tailwind base;
@tailwind components;
@tailwind utilities;


.type-text.direction-out + .type-text.direction-out,
.type-text.direction-in + .type-text.direction-in {
  @apply mb-[1px]
}
.type-text.direction-out + .type-text.direction-out img,
.type-text.direction-in + .type-text.direction-in img {
  @apply invisible
}
.type-text.direction-out + .type-text.direction-out .rounded-tr-none {
  @apply rounded-br-none
}
.type-text.direction-in + .type-text.direction-in .rounded-tl-none {
  @apply rounded-bl-none
}

.toggle-bg:after {
  background: #fff;
  border-color: #d1d5db;
  border-radius: 9999px;
  border-width: 1px;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  content: "";
  height: 1.25rem;
  left: .125rem;
  position: absolute;
  top: .125rem;
  transition-duration: .15s;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
  width: 1.25rem
}

input:checked+.toggle-bg:after {
  border-color: #fff;
  transform: translateX(100%)
}

input:checked+.toggle-bg {
  background: #1c64f2;
  border-color: #1c64f2
}